import React from 'react'
import './styles.css'

export default function Loading() {
    return (
        <div className='load' id='load' >
            <img src='logo.png' />
        </div>
    )
}
